import api from "../../api";
import { IAuthResponse } from "./models";

const apiURL = process.env.REACT_APP_SECRETS_API_URL;

const authAPI = api.injectEndpoints({
    endpoints: (build) => ({
      getAuth: build.mutation<IAuthResponse, {code: string}>({
        query: ({ code }) => {
          if (!/^[a-zA-Z0-9]+$/.test(code)) {
            throw new Error('Invalid code');
          }
          return {
            url: `${apiURL}/auth/${encodeURIComponent(code)}`,
            method: 'GET',
          };
        },
      }),
    }),
});

export default authAPI;
